import React, { useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { PageLayout } from "./components/common/PageLayout";
import { ExecuteContent } from "./components/execute/ExecuteContent";
import "./styles/App.css";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageLayout />} />
                <Route path="/execute" element={<ExecuteContent />} />
            </Routes>
        </BrowserRouter>
    );
};
