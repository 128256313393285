import { Navigate } from "react-router-dom";

/**
 * Redirects user to / following execution
 * @param props 
 */
export const ExecuteRedirect = (props) => {
    return (
        <Navigate to="/?e=true" replace={true} />
    );
};
