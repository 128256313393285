import React from "react";
import Badge from "react-bootstrap/Badge";

import { useEffect, useState } from "react";
import { StatusContent } from "./StatusContent";
import { StatusRedirect } from "./StatusRedirect";

/**
 * Renders a countdown timer that eventually redirects back to the StatusContent
 */
export const StatusStarting = (props) => {
    const [counter, setCounter] = useState(30);

    if (props.status === undefined) {
        return (
            <>
                <h1>Error..</h1>
                <p>props.status is undefined</p>
            </>
        );
    }

    // countdown and redirect back to 
    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    if (counter >= 1) {
        return (
            <>
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-sm-9 text-center">
                            <div class="card text-center">
                                <div class="card-body">
                                    <h2 class="card-title">OneWiki is starting up...</h2>
                                    <h5 class="card-text">Current Status <span class="badge badge-primary">{props.status.executionStatus}</span></h5>
                                    <br />
                                    <p class="cared-text">Refresh status in {counter} seconds.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // according to API status the app should be up so redirect
    if (props.status.redirect == true) {
        return (
            <StatusRedirect />
        );
    }

    // according to API status the step function is still executing
    return (
        <StatusContent />
    );

};