/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callStatusApi(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ environment: process.env.REACT_APP_ENV_NAME })
    };

    return fetch(process.env.REACT_APP_API_STATUS_ENDPOINT, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

/**
 * Attaches a given access token to an APIGw call. Executes a Step Function to start the environment
 * @param accessToken 
 */
export async function callExecuteApi(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ environment: process.env.REACT_APP_ENV_NAME }) 
    };

    return fetch(process.env.REACT_APP_API_EXECUTE_ENDPOINT, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}