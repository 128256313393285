import { useEffect, useState } from "react";
import { loginRequest } from "../../authConfig";
import { StatusData } from "./StatusData";
import { callStatusApi } from "../../api";

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";

export const StatusContent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [statusData, setStatusData] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none" && !statusData) {
            instance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                account: account
            }).then((response) => {
                callStatusApi(response.accessToken)
                    .then(response => setStatusData(response));
            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            scopes: loginRequest.scopes
                        }).then((response) => {
                            callStatusApi(response.accessToken)
                                .then(response => setStatusData(response));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }
    }, [account, inProgress, instance]);



    return (
        <>
            {statusData ?
                <StatusData statusData={statusData} executed={props.executed} />
                :
                null
            }
        </>
    );
};