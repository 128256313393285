import React from "react";
import { StatusStartContent } from "./StatusStartContent";
import { StatusStarting } from "./StatusStarting";
import { StatusStopping } from "./StatusStopping";
import { ApiError } from "../common/ApiError";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const StatusData = (props) => {
    if (props.statusData.Status === undefined) {
        return (
            <ApiError />
        );
    }

    const executed = props.executed

    const shouldBeStopped = (props.statusData.Status.desiredstate === "stopped") ? true : false
    const serviceStarting = JSON.parse(props.statusData.Status.startservices_locked)
    const serviceStopping = JSON.parse(props.statusData.Status.stopservices_locked)

    const executionstatus = props.statusData.Status.executionstatus === undefined ? null : props.statusData.Status.executionstatus


    // TODO: refactor
    // uh.. sorry..

    if (serviceStarting && serviceStopping) {
        return (
            <h1>The status endpoint reports that the service is both stopping and starting. Cannot continue.</h1>
        );
    }

    if (shouldBeStopped && !serviceStopping && !executed) {
        return (
            <StatusStartContent />
        );
    }

    if (!shouldBeStopped && !serviceStarting) {
        const status = {
            "desc": "service should be ONLINE",
            "redirect": true,
            "executionStatus": executionstatus
        }
        return (
            <StatusStarting status={status} />
        );
    }

    if (!shouldBeStopped && serviceStarting) {
        const status = {
            "desc": "service is still starting",
            "redirect": false,
            "executionStatus": executionstatus
        }
        return (
            <StatusStarting status={status} />
        );
    }

    if (!shouldBeStopped && serviceStopping) {
        return (
            <h1>Invalid state</h1>
        );
    }

    if (shouldBeStopped && serviceStopping) {
        return (
            <StatusStopping />
        );
    }

    const status = {
        "desc": "startup commencing",
        "redirect": false,
        "executionStatus": "Executing Startup"
    }
    return (
        <StatusStarting status={status} />
    );

};