//import { StatusExecutePrompt } from "./StatusExecutePrompt";

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";

// /**
//  * Redirects user to / following execution
//  * @param props 
//  */
// export const StatusExecutePrompt = (props) => {

//     return (
//         <div class="modal modal-alert position-static d-block py-5" tabindex="-1" role="dialog" id="modalChoice">
//             <div class="modal-dialog" role="document">
//                 <div class="modal-content rounded-4 shadow">
//                     <div class="modal-body p-4 text-center">
//                         <h5 class="mb-0">Confirm to start OneWiki</h5>
//                         <p class="mb-0">It can take up to 15 minutes for the app to become available. Your browser will be automatically redirected.</p>
//                     </div>
//                     <div class="modal-footer flex-nowrap p-0">
//                         <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right" onClick={() => navigate('/execute')}><strong>Yes, start</strong></button>
//                         <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Dismiss</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// /**
//  * Renders information about the user obtained from MS Graph
//  * @param props 
//  */
// export const StatusStartContent = () => {
//     //const [showModal, setShowModal] = useState(false);

//     // if (showModal) {
//     //     return (
//     //         <StatusExecutePrompt />
//     //     );
//     // }

//     return (
//         <div class="px-4 py-5 my-5 text-center">
//             <h2 class="display-5 fw-bold">{process.env.REACT_APP_ENV_APP_SHORT_NAME} has been stopped due to inactivity</h2>
//             <div></div>
//             <h5>It can take up to 15 minutes for the app to become available. Your browser will be automatically redirected.</h5>
//             <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
//                 <button type="button" class="btn btn-primary btn-lg px-4 gap-3 color-signin-button" onClick={() => setShowModal(true)}>Click here to start OneWiki</button>
//             </div>

//         </div>
//     );
// };

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const StatusStartContent = () => {
    let navigate = useNavigate();

    return (
        //<div class="px-4 py-5 my-5 text-center">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-sm-9 text-center">
                    <div class="card text-center">
                        <div class="card-body">
                            <h2 class="card-title">{process.env.REACT_APP_ENV_APP_SHORT_NAME} has been stopped due to inactivity</h2>
                            <h6 class="card-text">Please allow up to 15 minutes for OneWiki to completely start. Your browser will be automatically redirected when the environment is ready.</h6>
                            <br />
                            <button type="button" class="btn btn-primary btn-lg px-4 gap-3 color-signin-button" onClick={() => navigate('/execute')}>Click here to start OneWiki</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
