import { useEffect, useState } from "react";
import { StatusContent } from "./StatusContent";

/**
 * Renders a countdown timer that eventually redirects back to the StatusContent
 */
export const StatusStopping = () => {
    const [counter, setCounter] = useState(60);

    // countdown and redirect back to 
    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    if (counter >= 1) {
        return (
            <>
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-sm-9 text-center">
                            <div class="card text-center">
                                <div class="card-body">
                                    <h2 class="card-title">{process.env.REACT_APP_ENV_APP_SHORT_NAME} is stopping...</h2>
                                    <h6 class="card-text">OneWiki is being stopped due to inactivity.</h6>
                                    <br />
                                    <p class="card-text">Refreshing status in {counter} seconds.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <StatusContent />
    );

};