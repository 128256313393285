import { useEffect } from "react";

/**
 * Renders a redirect to OneWiki proper
 */
export const StatusRedirect = () => {
    // according to API status the app should be up so redirect
    useEffect(() => {
        window.location.href = process.env.REACT_APP_ENV_APP_URL;
    }, []);

    return (
        <div>
            <h2>Redirecting to OneWiki...</h2>
        </div>
    );
};