import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { useSearchParams } from 'react-router-dom'

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { StatusContent } from '../status/StatusContent'

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const [searchParams, setSearchParams] = useSearchParams();

    const executed = searchParams.get('e') !== null ? true : false

    return (
        <>
            <Navbar expand="sm" className="color-nav" variant="dark">
                <Navbar.Brand href="#home">
                    <img
                        alt="University of Florida logo"
                        src="/uf.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Start.OneWiki
                </Navbar.Brand>
                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </Navbar>

            <div class="px-4 py-5 my-5 text-center">
                <div className="App">
                    <AuthenticatedTemplate>
                        <StatusContent executed={executed} />
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <div class="px-4 py-5 my-5 text-center">
                            <h1 class="display-5 fw-bold">{process.env.REACT_APP_ENV_APP_SHORT_NAME} has been stopped due to inactivity</h1>
                            <p class="lead mb-4">Please sign-in to start the application</p>
                        </div>
                    </UnauthenticatedTemplate>
                </div>
            </div>
        </>
    );
};
