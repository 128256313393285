import { useEffect, useState } from "react";
import { loginRequest } from "../../authConfig";
import { ExecuteRedirect } from "./ExecuteRedirect";
import { callExecuteApi } from "../../api";

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";

export const ExecuteContent = () => {

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [execData, setExecData] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none" && !execData) {
            instance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                account: account
            }).then((response) => {
                callExecuteApi(response.accessToken)
                    .then(response => setExecData(response));
            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            scopes: loginRequest.scopes
                        }).then((response) => {
                            callExecuteApi(response.accessToken)
                                .then(response => setExecData(response));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }
    }, [account, inProgress, instance]);

    return (
        <>
            {execData ?
                <ExecuteRedirect execData={execData} cameFromExecute={true} />
                :
                null
            }
        </>
    );
};